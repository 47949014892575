import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiClient, { ErrorResponse } from '../utilities/apiClient';

export interface Patient {
    id?: string,
    patientNumber?: string,
    phoneNumber?: string,
    deactivated? : boolean,
    lastResponseDate?: string | null,
}

interface GetPatientsResponse {
    records: Patient[],
}

export const useGetPatients = () => {
    const {
        isLoading,
        isSuccess,
        data,
        isError,
        error,
        refetch,
    } = useQuery(['patients'], async () => {
        const response = await apiClient.get<GetPatientsResponse>('patients');

        return response.data?.records;
    });

    return {
        isLoading,
        isSuccess,
        data,
        isError,
        error,
        refetch
    };
};

export const useCreatePatient = () => {
    const queryClient = useQueryClient();

    const {
        isLoading,
        isSuccess,
        isError,
        error,
        mutate,
        reset,
    } = useMutation<any, ErrorResponse, Patient>(async (patient: Patient) => {
        return await apiClient.post('patients', patient);
    });

    const create = (patient: Patient) => {
        return new Promise<void>((resolve, reject) => {
            mutate(patient, {
                onSuccess: () => {
                    queryClient.invalidateQueries(['patients']);
                    resolve();
                },
                onError: (error) => {
                    reject(error.errors);
                }
            });
        });
    };

    return {
        isLoading,
        isSuccess,
        isError,
        error: error || {},
        reset,
        create,
    };
};

export const useUpdatePatient = () => {
    const queryClient = useQueryClient();

    const {
        isLoading,
        isSuccess,
        isError,
        error,
        mutate,
        reset,
    } = useMutation<any, ErrorResponse, Patient>(async (patient: Patient) => {
        return await apiClient.put(`patients/${patient.id}`, patient);
    });

    const update = (patient: Patient) => {
        return new Promise<void>((resolve, reject) => {
            mutate(patient, {
                onSuccess: () => {
                    queryClient.invalidateQueries(['patients']);
                    resolve();
                },
                onError: (error) => {
                    reject(error.errors);
                }
            });
        });
    };

    return {
        isLoading,
        isSuccess,
        isError,
        error: error || {},
        reset,
        update,
    };
};
