import Patients from './pages/Patients';
import Answers from "./pages/Answers";

interface Route {
    name: PageName,
    path: string,
    element: JSX.Element,
    isPrivate: boolean,
    showInMenu?: boolean,
    menuIndex?: number,
    icon?: string,
}

export enum PageName {
    Patients = 'Patients',
    Answers = 'Answers'
}

export const AppRoutes: Route[] = [
    {
        name: PageName.Patients,
        path: '/',
        element: <Patients />,
        isPrivate: true,
        showInMenu: true,
        menuIndex: 1,
        icon: 'cube',
    },
    {
        name: PageName.Answers,
        path: '/answers',
        element: <Answers />,
        isPrivate: true,
        showInMenu: true,
        menuIndex: 2,
        icon: 'question mark'
    }
];
