import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { useMenu } from '../hooks/useMenu';
import { AppRoutes } from '../routes';

const Menubar = () => {
    const { isMenuBarOpened, selectedMenuBarOption } = useMenu();
    const [menuSelected, setMenuSelected] = useState(0);

    const menu = useMemo(() => {
        return AppRoutes
            .filter(route => route.showInMenu)
            .map(route => ({
                name: route.name,
                path: route.path,
                icon: route.icon,
            }));
    }, []);

    useEffect(() => {
        const index = menu.findIndex(item => item.name === selectedMenuBarOption);
        setMenuSelected(index);
    }, [selectedMenuBarOption, menu]);

    const menuBarClasses = {
        'menu-bar': true,
        'menu-sticky': true,
        'menu-hidden': !isMenuBarOpened,
    }

    return (
        <>
            <aside className={classNames(menuBarClasses)}>
                <div className="menu-items">
                    {menu.map((menuItem, index) =>
                        <Link key={index} to={menuItem.path} className={classNames('link', { active: index === menuSelected })}>
                            <span className={classNames('icon la', `la-${menuItem.icon}`)}></span>
                            <span className="title">{menuItem.name}</span>
                        </Link>
                    )}
                </div>
            </aside>
        </>
    );
}

export default Menubar;
